.home-container {
    height: 100wh;
    padding-bottom: 24px;
}
.post-area {
    max-width: 40vw;
    margin: 0 auto;
    position: relative;
    margin-top: 24px;
    /* border: 1px solid; */
    padding: 12px 20px;
    border: 2px solid #276CD0;
    border-radius: 12px;
}
.post-area textarea {
    width: 100%;
    border: 2px solid #276CD0;
    border-radius: 6px;
    height: 120px;
}
.post-area .btn {
    margin-left: 86%;
    border: none;
    padding: 12px 24px;
    border-radius: 12px;
    background-color: #276CD0;
    color: #fff;
    font-weight: bold;
    margin-top: 12px;
}
.title {
    margin-bottom: 12px;
}
.title-input {
    padding: 6px;
    width: 98%;
    border: 2px solid #276CD0;
    border-radius: 6px;
}
.post-title {
    padding: 0 12px;
}
.post-author {
    padding: 12px;
}
.post-content {
    padding: 0 12px;
    /* height: 36px; */
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}