.header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #eee;
}
.h-primary-section {
    display: flex;
    padding: 0 24px;
}
.h-secondary-section {
    padding-right: 24px;
}
.logo {
    margin-right: 24px;
}
.logo ~ h3:hover {
    color: #276CD0;
    cursor: pointer;
}
.header-item {
    margin-right: 24px;
}
.header .btn {
    background-color: #276CD0;
    border-width: 0;
    border-radius: 12px;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    margin: 6px 0;
}
.header .circle {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: rgb(180, 179, 179);
    margin-top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.header h3 {
    padding-top: 4px;
}